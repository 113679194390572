.eventBg{
    background-image: url('../../img/JWG_4520.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .eventContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.content {
  }