

.navBar {
  position: fixed;
  width: 100%;
  height: 100px;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  padding-inline-start: 0px;
  }

  .eventBar {
    list-style-type: none;
    overflow: hidden;
    background-color: #db5f5f;
    margin-block-start: 0;
    margin-block-end: 0;
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline-start: 0px;
    }

  .navButton {
  float: left;
  justify-content: center;
    align-items: center;
    display: flex;
  }
  .navLink {
    text-decoration: none;
  }
  .navBox {
    text-decoration: none;
    display: block;
    color: white;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: .5s;
  }
  .eventBox {
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
  }
  .eventBox-active {
    text-decoration: none;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: #c93b3b;
  }
  .eventBox:hover {
    text-decoration: none;
    background-color: #c93b3b;
  }
  .navBox:hover {
    text-decoration: none;
    background-color: #efefef;
  }



  .signInLink {
    text-decoration: none;
    display: block;
    color: black;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .signOutButton {
    text-decoration: none;
    display: block;
    color: white;
    min-width: 120px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-width: 0px;
    background-color: #333333;
  }
  .signOutButton:hover {
    text-decoration: none;
    background-color: #333999;
  }
  
  .dropbtn {
    background-color: rgba(124,10,1);
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: .5s;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {
                                      visibility: visible;
                                      opacity: 1;
                                      transition: .5s;
                                    }
  
  .dropdown:hover .dropbtn {background-color: rgba(98,8,2);}

  .navLogo {
    width: 75px;
    height: 75px;
}

.navLogoBox {
  display:flex;
  flex: 1;
  justify-content: flex-start;
  padding: 10px;
}

.navButtonsBox {
  display:flex;
  flex: 1;
  justify-content: flex-end;
  padding: 10px;
}