.boothBox {
    width: 300px;
    height: 500px;
    display: flex;
    background-color: rgba(124,20,21,1);
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: left;
    text-align: center;
    justify-content: center;

}
h2 {
    color: white;
    margin: 10px;
}
.boothsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


}
.boothContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
    
}
.boothInfo {
    height: 75px;
    width: 250px;
    overflow: scroll;
    border-style: solid;
    background-color: rgba(180,20,21,1);
    border-color: rgba(180,20,21,1);
    border-radius: 10px;
    border-width: 5px;

}
.input {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: center;
    font-size: 35px;
}

textArea {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: left;
    font-size: 1em;
    width: 250px;
    resize: none;
}
textarea#styled {
	box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: center;
    font-size: 2em;
    width: 300px;
}
input {
	box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    display: flex;
    text-align: center;
    font-size: 1em;
    resize: none;
    width: 300px;
}
.crossUp {
    background-color: rgba(255, 255, 255,.7);
    height: 255px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 30px,
}

.crossFlat {
    background-color: rgba(255, 255, 255,.7);
    display: flex;
    height: 45;
    width: 255;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
  .idWrapper {
    width: 300px;
    background-color: rgba(124,20,21,1); 
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 2px;
  }
  .idTitle {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    justify-content: center;
    font-size: 2em;
  }
  .id {
    font-size: 1em;
    color: white;
    display: flex;
    justify-content: center; 
  }
  .submitButton {
    color: white;
    width: 300px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-width: 0px;
    border-radius: 0px 0px 10px 10px;
    background-color: rgba(124,20,21,1); 
    margin-top: 2px
  }
  .submitButton:hover {
    text-decoration: none;
    background-color: rgba(180,20,21,1); 
  }
  button {
    border-style: none;  
  }
  .buttonText {
    color: #ffffff;
    font-size: 2em;
  }
  .boothButtons {
    width:50px;
    height: 50px;
    background-color: rgba(124,20,21,1); 
    border-radius: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  .circle {
    position: fixed;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    bottom: 10%;
    left: 50%;
    margin-left: -37.5px; /* Negative half of width. */
    background-color: rgba(124,20,21,1); 
    box-shadow: 0px 0px 5px black;
  }
  
  .horizontal-plus {
    position: relative;
    background-color: #FFFFfF;
    width: 50%;
    height: 12.5%;
    left: 25%;
    top: 43.75%;
    border-radius: 5px;
  }
  .vertical-plus {
    position: relative;
    background-color: #FFFFfF;
    width: 12.5%;
    height: 50%;
    left: 43.75%;
    top: 12.5%;
    border-radius: 5px;
  }

  .descriptionBox {
    display: flex;
    flex: 2;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    overflow: scroll;
  }
  .deleteButton {
    width: 300px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color:rgba(124,20,21,1); 
    border-radius: 30px 30px 0px 0px;
  }

  .deleteButton:hover {
    background-color: rgb(156, 52, 54); 
  }
  

  .deleteText {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;

  }

  
