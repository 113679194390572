@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");

h1 {
    font-family: 'Inconsolata', monospace;

    color: white;
  }

body {
    font-family: 'Inconsolata', monospace;
    background-size: cover;
    color: white;
}

input {
    font-family: 'Inconsolata', monospace;
}

input:focus {
    outline: none;
}

textarea {
    font-family: 'Inconsolata', monospace;
}

button {
    font-family: 'Inconsolata', monospace;
}