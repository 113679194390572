

h2 {
    color: #fffff0;
    margin: 10px;
}
h3 {
  color: #fffff0;
  margin: 10px;
}
.boothsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.contentContainer {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.membersWrapper {
  position: fixed;
  right: 0;
  bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden; 
  vertical-align:top;
  justify-content: center;
  background-color: rgba(230, 169, 1, 0.6);
  border-radius: 90px 0px 0px 90px;
  box-shadow: -1px 0px 5px black;
  scroll-snap-type: y mandatory;
}

.membersPosition {
  position: relative;
  right: -500px;
}

.memberBox {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 20px;
  justify-content: flex-end;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  
}

.infoBox {
  display: flex;
  height:165px;
  width: 300px;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  margin-left: 2.5px;
}

.infoBg {
  height: 125px;
}

.infoContent {
  align-items: flex-start;
}

.infoLine {
  display: flex;
  flex-direction: row;
}
.info {
  align-items: center;
  justify-content: center;
  display: flex;
}

.name {
  font-size: 25px;
  color: #fffff0;
  font-weight: 600;
}

.position {
  font-size: 20px;
  color: #fffff0;
}

.pictureBox {
  display: flex;
padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  margin-right: 2.5px;
}


.buttonBox {
  padding: 20px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  border-radius: 0px 90px 90px 0px;
}
.buttonBg {
  background-color: rgba(124,20,22,1);
  display: flex;
  height: 125px;
  width: 125px;
  border-radius: 0px 90px 90px 0px;
  align-items: center;
  justify-content: center;
}
.buttonContent {
  font-size: 50px;
  color: white;
}

button {
  background-color: rgba(0,0,0,0);
}
.inputChannel {
  color: black;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:35px;
}

.slackContainer {
  display: flex;
  flex-direction: column;
}

.coreTitle {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}
.coreTitleBox {
  height: 150px;
  width: 170px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 45px;
}

.homeBg {
  background-image: url('../../img/JWG_3041.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: block;
  
}

.homeContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  flex-grow: 1;
}

.dailyBox {
  display: flex;
  background-color: rgba(124,20,22,1);
  width: 250px;
  margin: 5px;
  flex-direction: column;
  padding: 20px;
}

.dateBox {
  display: flex;
  background-color: rgba(124,20,22,1);
  margin: 5px;
  width: 100px;

  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
  justify-content: center;
  align-items: center;
}

.dailyBox:hover {
  background-color: rgba(80,20,22,1);
  transition: .5s,
}

.calEvTitle {
font-size: 1em;
}

.calEvWrapper {
  background-color: rgba(255,255,255,.3);
  width: 300;
  max-height: 400px;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px 30px 30px 0px;
}