@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
    font-family: 'Inconsolata', monospace;

    color: white;
  }

body {
    font-family: 'Inconsolata', monospace;
    background-size: cover;
    color: white;
}

input {
    font-family: 'Inconsolata', monospace;
}

input:focus {
    outline: none;
}

textarea {
    font-family: 'Inconsolata', monospace;
}

button {
    font-family: 'Inconsolata', monospace;
}


.navBar {
  position: fixed;
  width: 100%;
  height: 100px;
  list-style-type: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  }

  .eventBar {
    list-style-type: none;
    overflow: hidden;
    background-color: #db5f5f;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    }

  .navButton {
  float: left;
  justify-content: center;
    align-items: center;
    display: flex;
  }
  .navLink {
    text-decoration: none;
  }
  .navBox {
    text-decoration: none;
    display: block;
    color: white;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-transition: .5s;
    transition: .5s;
  }
  .eventBox {
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
  }
  .eventBox-active {
    text-decoration: none;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: #c93b3b;
  }
  .eventBox:hover {
    text-decoration: none;
    background-color: #c93b3b;
  }
  .navBox:hover {
    text-decoration: none;
    background-color: #efefef;
  }



  .signInLink {
    text-decoration: none;
    display: block;
    color: black;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .signOutButton {
    text-decoration: none;
    display: block;
    color: white;
    min-width: 120px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-width: 0px;
    background-color: #333333;
  }
  .signOutButton:hover {
    text-decoration: none;
    background-color: #333999;
  }
  
  .dropbtn {
    background-color: rgba(124,10,1);
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {
                                      visibility: visible;
                                      opacity: 1;
                                      -webkit-transition: .5s;
                                      transition: .5s;
                                    }
  
  .dropdown:hover .dropbtn {background-color: rgba(98,8,2);}

  .navLogo {
    width: 75px;
    height: 75px;
}

.navLogoBox {
  display:flex;
  flex: 1 1;
  justify-content: flex-start;
  padding: 10px;
}

.navButtonsBox {
  display:flex;
  flex: 1 1;
  justify-content: flex-end;
  padding: 10px;
}


h2 {
    color: #fffff0;
    margin: 10px;
}
h3 {
  color: #fffff0;
  margin: 10px;
}
.boothsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.contentContainer {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.membersWrapper {
  position: fixed;
  right: 0;
  bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden; 
  vertical-align:top;
  justify-content: center;
  background-color: rgba(230, 169, 1, 0.6);
  border-radius: 90px 0px 0px 90px;
  box-shadow: -1px 0px 5px black;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

.membersPosition {
  position: relative;
  right: -500px;
}

.memberBox {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 20px;
  justify-content: flex-end;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  
}

.infoBox {
  display: flex;
  height:165px;
  width: 300px;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  margin-left: 2.5px;
}

.infoBg {
  height: 125px;
}

.infoContent {
  align-items: flex-start;
}

.infoLine {
  display: flex;
  flex-direction: row;
}
.info {
  align-items: center;
  justify-content: center;
  display: flex;
}

.name {
  font-size: 25px;
  color: #fffff0;
  font-weight: 600;
}

.position {
  font-size: 20px;
  color: #fffff0;
}

.pictureBox {
  display: flex;
padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  margin-right: 2.5px;
}


.buttonBox {
  padding: 20px;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(124,20,22,1);
  border-radius: 0px 90px 90px 0px;
}
.buttonBg {
  background-color: rgba(124,20,22,1);
  display: flex;
  height: 125px;
  width: 125px;
  border-radius: 0px 90px 90px 0px;
  align-items: center;
  justify-content: center;
}
.buttonContent {
  font-size: 50px;
  color: white;
}

button {
  background-color: rgba(0,0,0,0);
}
.inputChannel {
  color: black;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:35px;
}

.slackContainer {
  display: flex;
  flex-direction: column;
}

.coreTitle {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}
.coreTitleBox {
  height: 150px;
  width: 170px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 45px;
}

.homeBg {
  background-image: url(/static/media/JWG_3041.1ccaa1fe.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: block;
  
}

.homeContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  flex-grow: 1;
}

.dailyBox {
  display: flex;
  background-color: rgba(124,20,22,1);
  width: 250px;
  margin: 5px;
  flex-direction: column;
  padding: 20px;
}

.dateBox {
  display: flex;
  background-color: rgba(124,20,22,1);
  margin: 5px;
  width: 100px;

  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
  justify-content: center;
  align-items: center;
}

.dailyBox:hover {
  background-color: rgba(80,20,22,1);
  -webkit-transition: .5s;
  transition: .5s
}

.calEvTitle {
font-size: 1em;
}

.calEvWrapper {
  background-color: rgba(255,255,255,.3);
  width: 300;
  max-height: 400px;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px 30px 30px 0px;
}
.bg-img {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(/static/media/JWG_4312.56b8931e.jpg);
}

.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signInBox {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    justify-content: center;
    border: 1px solid #e6e6e6;
}



.logoBox{
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}

.loginLogo {
    width: 200px;
    height: 200px;
}

.inputBox {
    display: flex;
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #efefef;
    margin: 10px;
    height: 1.5em;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.signInButton {
    display: flex;
    width: 100%;
    background-color: rgba(124,10,1);
    border: 1px solid rgb(145, 22, 13);
    margin: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.signInText {
    font-size: 1.5em;
    color: white;
}

.eventBg{
    background-image: url(/static/media/JWG_4520.210f258e.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .eventContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.content {
  }
.boothBox {
    width: 300px;
    height: 500px;
    display: flex;
    background-color: rgba(124,20,21,1);
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: left;
    text-align: center;
    justify-content: center;

}
h2 {
    color: white;
    margin: 10px;
}
.boothsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


}
.boothContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
    
}
.boothInfo {
    height: 75px;
    width: 250px;
    overflow: scroll;
    border-style: solid;
    background-color: rgba(180,20,21,1);
    border-color: rgba(180,20,21,1);
    border-radius: 10px;
    border-width: 5px;

}
.input {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: center;
    font-size: 35px;
}

textArea {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: left;
    font-size: 1em;
    width: 250px;
    resize: none;
}
textarea#styled {
	box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    text-align: center;
    font-size: 2em;
    width: 300px;
}
input {
	box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    display: flex;
    text-align: center;
    font-size: 1em;
    resize: none;
    width: 300px;
}
.crossUp {
    background-color: rgba(255, 255, 255,.7);
    height: 255px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 30px,
}

.crossFlat {
    background-color: rgba(255, 255, 255,.7);
    display: flex;
    height: 45;
    width: 255;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
  .idWrapper {
    width: 300px;
    background-color: rgba(124,20,21,1); 
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 2px;
  }
  .idTitle {
    box-sizing: border-box;
    border: none;
    background-color: rgba(0,0,0,0);
    color: white;
    display: flex;
    justify-content: center;
    font-size: 2em;
  }
  .id {
    font-size: 1em;
    color: white;
    display: flex;
    justify-content: center; 
  }
  .submitButton {
    color: white;
    width: 300px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-width: 0px;
    border-radius: 0px 0px 10px 10px;
    background-color: rgba(124,20,21,1); 
    margin-top: 2px
  }
  .submitButton:hover {
    text-decoration: none;
    background-color: rgba(180,20,21,1); 
  }
  button {
    border-style: none;  
  }
  .buttonText {
    color: #ffffff;
    font-size: 2em;
  }
  .boothButtons {
    width:50px;
    height: 50px;
    background-color: rgba(124,20,21,1); 
    border-radius: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  .circle {
    position: fixed;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    bottom: 10%;
    left: 50%;
    margin-left: -37.5px; /* Negative half of width. */
    background-color: rgba(124,20,21,1); 
    box-shadow: 0px 0px 5px black;
  }
  
  .horizontal-plus {
    position: relative;
    background-color: #FFFFfF;
    width: 50%;
    height: 12.5%;
    left: 25%;
    top: 43.75%;
    border-radius: 5px;
  }
  .vertical-plus {
    position: relative;
    background-color: #FFFFfF;
    width: 12.5%;
    height: 50%;
    left: 43.75%;
    top: 12.5%;
    border-radius: 5px;
  }

  .descriptionBox {
    display: flex;
    flex: 2 1;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    overflow: scroll;
  }
  .deleteButton {
    width: 300px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color:rgba(124,20,21,1); 
    border-radius: 30px 30px 0px 0px;
  }

  .deleteButton:hover {
    background-color: rgb(156, 52, 54); 
  }
  

  .deleteText {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;

  }

  

