.bg-img {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('../../img/JWG_4312.jpg');
}

.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signInBox {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    justify-content: center;
    border: 1px solid #e6e6e6;
}



.logoBox{
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}

.loginLogo {
    width: 200px;
    height: 200px;
}

.inputBox {
    display: flex;
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #efefef;
    margin: 10px;
    height: 1.5em;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.signInButton {
    display: flex;
    width: 100%;
    background-color: rgba(124,10,1);
    border: 1px solid rgb(145, 22, 13);
    margin: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.signInText {
    font-size: 1.5em;
    color: white;
}
